import React from "react"
import { Footer } from "@/components/footer"
import SEO from "@/components/seo"
import { Login } from "@/components/login"

const Home: React.FC = () => {
  return (
    <>
      <SEO title="Login Page" />
      <Login />
      <Footer />
    </>
  )
}

export default Home
