// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
// import firebase from "gatsby-plugin-firebase"

// const getAnalytics = firebase.getAnalytics
// const initializeApp = firebase.initializeApp

import {
  getAuth,
  FacebookAuthProvider,
  GoogleAuthProvider,
} from "firebase/auth"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCbz_bH_TXGvqi_03u3yBS9jgC0pVXPNf0",
  authDomain: "moflip.firebaseapp.com",
  projectId: "moflip",
  storageBucket: "moflip.appspot.com",
  messagingSenderId: "1034854749687",
  appId: "1:1034854749687:web:5f33f9fec0132bc3868af2",
  measurementId: "G-BH8569E9LQ",
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const providerFacebook = new FacebookAuthProvider()
const providerGoogle = new GoogleAuthProvider()

export { auth, providerFacebook, providerGoogle }
