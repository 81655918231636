import React from "react"
import { Link } from "gatsby"
import { text } from "@fortawesome/fontawesome-svg-core"
import { TextInput, PasswordInput } from "../form"
import { userLogin } from "../../services/user-service"
import { signInWithPopup, signInWithRedirect } from "firebase/auth"
import {
  auth,
  providerFacebook,
  providerGoogle,
} from "../../services/firebase-config"
import { setCookie } from "typescript-cookie"
import axiosConfig from "../../services/axios-config"
import clsx from "clsx"
import whiteLogo from "../../images/borneo-white-logo.png"
import facebookLogo from "../../images/facebook-logo.png"
import googleLogo from "../../images/google-logo.png"

export const Login = () => {
  const [loading, setLoading] = React.useState(false)
  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [error, setError] = React.useState("")

  const handleMailChange = e => {
    setEmail(e.target.value.trim())
  }

  // Login Handler
  const handleLogin = async () => {
    setLoading(true)
    try {
      const response = await userLogin({ email, password }).then(() => {
        if (typeof window !== "undefined") {
          window.location.href = "/"
        }
      })
    } catch (error) {
      console.error(error)
      setError(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  const autenticated = async (user, provider) => {
    // save token with savecookie
    try {
      const token = await user.getIdToken()
      const resultAuth = await axiosConfig.post(`/users/sso`, {
        token: user?.accessToken,
      })
      setCookie("access_token", resultAuth.data?.data?.access_token, {
        expires: 1,
        path: "/",
      })
    } catch (error) {
      console.log(error)
    }
  }

  const loginFacebook = () => {
    signInWithPopup(auth, providerFacebook)
      .then(res => {
        autenticated(res?.user, res?.providerId)
          .then(() => {
            if (typeof window !== "undefined") {
              window.location.href = "/"
            }
          })
          .catch(err => {
            alert("Terjadi kesalahan saat login")
          })
      })
      .catch(err => {
        console.trace(err)
      })
  }

  const loginGoogle = () => {
    signInWithPopup(auth, providerGoogle)
      .then(res => {
        autenticated(res?.user, res?.providerId)
          .then(() => {
            if (typeof window !== "undefined") {
              window.location.href = "/"
            }
          })
          .catch(err => {
            alert("Terjadi kesalahan saat login")
          })
      })
      .catch(err => {
        console.trace(err)
      })
  }

  const BolafyCard = ({
    className,
    bgColor = "bolafy-bg-gradient",
    children,
  }) => {
    return (
      <div className={className}>
        <div className="rounded-xl bg-gradient-to-br from-red-300 via-red-700 to-red-300 p-[1px] shadow-md shadow-mirage">
          <div
            className={`${bgColor} rounded-xl p-4 text-gray-300 bg-blend-overlay`}
          >
            {children}
          </div>
        </div>
      </div>
    )
  }

  const TiketNFTLoginInfo = () => (
    <BolafyCard className="mt-6 mb-6 text-center">
      <div className="mt-2 mb-4 h-12 w-36 mx-auto">
        <img src={whiteLogo} alt="Logo Persija" />
      </div>
      <div className="ml-2 text-sm lg:text-base">
        Silahkan masuk dengan menggunakan akun <b>Bolafy</b>.
      </div>
    </BolafyCard>
  )

  return (
    <>
      <div className=" bg-black flex min-h-screen items-center justify-center">
        <div className="mx-auto flex min-h-screen w-full max-w-xl flex-col justify-center overflow-y-hidden rounded-2xl p-4 sm:min-h-0 md:border-2 md:border-red-700 md:p-6">
          {/* <h2>Masuk</h2> */}

          <TiketNFTLoginInfo />

          {/* <TextInput
            onChange={handleMailChange}
            value={email}
            name="email"
            type="text"
            label="Alamat E-mail"
            placeholder="Alamat E-mail"
            autoComplete="off"
            className="mt-6"
            error={error}
          />

          <PasswordInput
            onChange={e => setPassword(e.target.value)}
            name="password"
            label="Password"
            placeholder="Password"
            className="mt-6"
            error={error}
          />

          <div className="mt-3 flex justify-between">
            <Link to="https://bolafy.com/forgot-password">
              <a className="underline">Lupa Password?</a>
            </Link>
          </div> */}

          {/* button login
          <div className="mt-8">
            <button
              onClick={handleLogin}
              type="submit"
              className={clsx(
                "w-full hover:bg-black hover:text-white rounded-2xl bg-orange-600 p-4 text-lg font-bold text-black",
                true && !loading
                  ? "bg-purple-700 text-white"
                  : "bg-purple-200 text-white/25"
              )}
              disabled={loading || false}
            >
              {loading ? "Loading ..." : "Masuk"}
            </button>
          </div> */}

          {/* <div className="mt-4 flex justify-center">
            <div className="inline-flex items-center space-x-1">
              <span>Belum terdaftar?</span>

              <Link to="https://bolafy.com/register">
                <a className="underline">Daftar</a>
              </Link>
            </div>
          </div> */}

          <>
            {" "}
            <div className={"mt-6"}>
              <button
                disabled={false}
                className={`${
                  false
                    ? "bg-[#ff7f00]/25 text-white/25"
                    : "bg-red-400 hover:bg-gray-800 text-white"
                } align-center flex w-full cursor-pointer flex-row items-center justify-center rounded-2xl p-3 font-semibold`}
                onClick={loginFacebook}
              >
                <img
                  src={facebookLogo}
                  className={"mr-4 h-8 w-8"}
                  alt={"facebook"}
                />
                <label className={"cursor-pointer"}>
                  Masuk dengan Facebook
                </label>
              </button>
            </div>
            <div className={"mt-6"}>
              <button
                disabled={false}
                className={`${
                  false
                    ? "bg-[#ff7f00]/25 text-white/25"
                    : "bg-red-400 hover:bg-gray-800 text-white"
                } align-center flex w-full cursor-pointer flex-row items-center justify-center rounded-2xl p-3 font-semibold`}
                onClick={loginGoogle}
              >
                <img
                  src={googleLogo}
                  className={"mr-4 h-8 w-8"}
                  alt={"google"}
                />
                <label className={"cursor-pointer"}>Masuk dengan Google</label>
              </button>
            </div>
          </>
        </div>
      </div>
    </>
  )
}
