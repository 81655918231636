import axios from "axios"
import { getCookie, setCookie, removeCookie } from "typescript-cookie"

const instance = axios.create({
  baseURL: "https://api.tiketnft.com",
})
instance.defaults.headers.common["source"] = "mobile"

export const userLogin = async ({ email, password }) => {
  try {
    const response = await instance.post(`/users/login/mobile`, {
      email,
      password,
    })

    setCookie("access_token", response?.data?.data?.access_token, {
      expires: 1,
    })

    return response?.data
  } catch (error) {
    throw error
  }
}
